/*
 * @Descripttion: project name
 * @version: 1.0
 * @Author: marui
 * @Date: 2023-04-11 14:15:04
 * @LastEditors: marui
 * @LastEditTime: 2023-04-11 17:02:08
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import axios from ''
import Vant from "vant";
import { Toast } from "vant";
import "vant/lib/index.css";
import "@/common/css/index.less";

Vue.config.productionTip = false;
Toast.setDefaultOptions({ duration: 3000 });
Vue.use(Toast);
Vue.use(Vant);
var bus = new Vue()
Vue.prototype.bus = bus
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
