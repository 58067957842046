/*
 * @Descripttion: project name
 * @version: 1.0
 * @Author: marui
 * @Date: 2023-04-11 14:15:04
 * @LastEditors: marui
 * @LastEditTime: 2023-04-12 08:37:30
 */
export default {
  setUserId(state, data) {
    state.userId = data;
    localStorage.setItem("userId", data);
  },
  setUrl(state, data) {
    state.localhostUrl = data;
    localStorage.setItem("H5Url", data);
  },
  setClickId(state, data) {
    state.clickid = data;
  },
  setOrderId(state, data) {
    state.orderId = data;
    localStorage.setItem("orderId", data);
  },
  setPathName(state, data) {
    state.pathName = data;
    localStorage.setItem("pathName", data);
  },
  setDyClickId(state, data) {
    state.dyClickid = data;
    localStorage.setItem("dyClickid", data)
  }
};
