/*
 * @Descripttion: project name
 * @version: 1.0
 * @Author: marui
 * @Date: 2023-04-02 09:14:28
 * @LastEditors: hujie striveyf@dingtalk.com
 * @LastEditTime: 2023-09-23 10:45:40
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index'

Vue.use(VueRouter);

const routes = [
  {
    path: '/mall/:code',
    name: "mall",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/home.vue"),
  },
  {
    path: '/queryOrder',
    name: "queryOrder",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/queryOrder.vue"),
    meta: {
      title: '订单查询'
    },
  },
  {
    path: '/queryRefund',
    name: "queryRefund",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/queryRefund.vue"),
    meta: {
      title: '申请退款'
    },
  },
  {
    path: '/payOrder',
    name: "payOrder",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/payOrder.vue"),
    meta: {
      title: '支付订单'
    },
  },
  {
    path: '/submitRefund',
    name: "submitRefund",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/submitRefund.vue"),
  },
  {
    path: '/orderResult',
    name: "orderResult",
    component: () => import(/* webpackChunkName: "home" */ "@/views/mall/orderResult.vue"),
  },
  {
    path: "/placeAnOrder/templateShop/:code/:id",
    name: "placeAnOrderMall",
    component: () =>
      import(/* webpackChunkName: "placeAnOrder" */ "@/views/placeOrder/templateShop.vue"),
  },
  {
    path: "/phoneOrder/templateShop/:code/:id",
    name: "phoneOrderMall",
    component: () =>
      import(/* webpackChunkName: "placeAnOrder" */ "@/views/phoneOrderTwo/templateShop.vue"),
  },
  {
    path: "/allInOne/templateShop/:code/:id/:productId",
    name: "allInOneMall",
    component: () =>
      import(/* webpackChunkName: "allInOne" */ "@/views/allInOne/templateShop.vue"),
  },
  {
    path: "/home/:id",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
  },
  {
    path: "/homePage/:id",
    name: "homePage",
    component: () =>
      import(/* webpackChunkName: "home2" */ "@/views/home/home2.vue"),
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单查询' // 列表
    },
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/index.vue')
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      title: '投诉'
    },
    component: () => import(/* webpackChunkName: "report" */ '@/views/report/index.vue')
  },
  {
    path: "/placeAnOrder/index/:id",
    name: "placeAnOrder",
    component: () =>
      import(/* webpackChunkName: "placeAnOrder" */ "@/views/placeOrder/index.vue"),
  },
  {
    path: "/placeAnOrder/tibet/:id",
    name: "tibetOrder",
    component: () =>
      import(/* webpackChunkName: "tibetOrder" */ "@/views/placeOrder/tibet.vue"),
  },
  {
    path: "/phoneOrder/index/:id",
    name: "phoneOrder",
    component: () =>
      import(/* webpackChunkName: "phoneOrder" */ "@/views/phoneOrderTwo/index.vue"),
  },
  {
    path: "/newphoneOrder/index/:id",
    name: "newphoneOrder",
    component: () =>
      import(/* webpackChunkName: "phoneOrder" */ "@/views/phoneOrderTwo/index.vue"),
  },
  {
    path: "/allInOne/index/:id/:productId",
    name: "allInOne",
    component: () =>
      import(/* webpackChunkName: "allInOne" */ "@/views/allInOne/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404/index"),
  },
  {
    path: "/soldOut",
    name: "SoldOut",
    component: () => import(/* webpackChunkName: "SoldOut" */ "@/views/soldOut/index"),
  },
  {
    path: "/result/:id",
    name: "result",
    component: () =>
      import(/* webpackChunkName: "result" */ "@/views/result/index"),
  },
  {
    path: "/updateCard",
    name: "updateCard",
    component: () => import(/* webpackChunkName: "updateCard" */ "@/views/updateCard/index"),
  },
  {
    path: "/updateResult/:id",
    name: "updateResult",
    component: () => import(/* webpackChunkName: "updateResult" */ "@/views/updateCard/result"),
  },
  {
    path: "/a/:phone",
    name: "a",
    component: () => import(/* webpackChunkName: "a" */ "@/views/a")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

if (window.localStorage.getItem('userId') || window.localStorage.getItem('H5Url')) {
  store.commit('setUserId', window.localStorage.getItem('userId'))
  store.commit('setUrl', window.localStorage.getItem('H5Url'))
}
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title // 设置页面标题
  } else {
    document.title = '商城'
  }
  next()
})

export default router;
